const list = [
  { value: 'CEO / President', label: 'CEO / President' },
  { value: 'CHRO / CLO', label: 'CHRO / CLO' },
  { value: 'VP / Executive', label: 'VP / Executive' },
  { value: 'Director of HR / L&D / OD', label: 'Director of HR / L&D / OD' },
  { value: 'Director', label: 'Director' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Consultant / Executive Coach', label: 'Consultant / Executive Coach' },
  { value: 'Trainer / Facilitator / Teacher', label: 'Trainer / Facilitator / Teacher' },
  { value: 'Student / Participant', label: 'Student / Participant' },
  { value: 'Other', label: 'Other' }
];

module.exports = {
  list: list
};