import React, { useState } from 'react';
import Modal from 'react-modal';
import TrendsForm from '../../components/trendsForm';
import CCLButton from '../../components/cclButton';
import cclTrends from '../../images/homepage-trends.jpg';
import closeIcon from '../../images/close_btn.png';
import './cclTrends.scss';

const CCLTrends = () => {
  let [showForm, setShowForm] = useState('hide');

  const toggleForm = () => {
    setShowForm(showForm ? 'hide' : 'show');
    let thanksPage = document.getElementById('ccl-trends');
    thanksPage.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <div id="ccl-trends">
      <div className={'trends-content ' + showForm}>
        <div className="trends-item">
          <h2><span className="ccl-yellow-hover">How will you thrive in the face of disruption?</span></h2>
          <h2 className="ccl-teal">Look in the mirror.</h2>
        </div>
        <div className="trends-item">
          <img src={cclTrends} className="trend-image" alt="How to thrive in the face of disruption?" />
          <CCLButton
            onClick={() => {
              setShowForm('show');
            }}
            label="Get Our Trends Report"
            type="submit"
            style="primary"
            arrow="true"
          ></CCLButton>
        </div>
      </div>
      <div className={'trend-form ' + showForm}>
        <button
          id="close-form"
          onClick={() => {
            toggleForm();
          }}
        >
          <span>X</span>
        </button>
        <TrendsForm closeForm={toggleForm} />
      </div>
    </div>
  );
};

export default CCLTrends;
