import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import CCLButton from './cclButton';
import Countries from '../constants/countries';
import userRoles from '../constants/roles';
import './trendForm.scss';

const TrendsForm = props => {
  let [getUpdates, setGetUpdates] = useState(false);
  let [showThanks, setShowThanks] = useState(false);
  let [trendInvalidForm, setTrendInvalidForm] = useState(false);
  let timestamp = moment().format('MM/DD/YYYY');
  let utms = {};

  const isObjEmpty = (obj) => {
    if(!obj)
      return true;
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }
  
  const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  const availableParams = ['utm_source', 'utm_medium', 'utm_content', 'utm_term', 'utm_campaign', 'referrer'];

  const filterParams = (params) => {
    return params ? Object.keys(params)
    .filter(key => availableParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {}) : {};
  }

  const getParams = (url) => {
    let params = {};
    let parser = document.createElement('a');
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      if(vars[i]) {
        let pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }

    const referrer = document.referrer;
    const domainReferrer = extractHostname(referrer);
    const currentSiteDomain = extractHostname(window.location.href);
    if (referrer.startsWith('https://www.facebook.com') || referrer.startsWith('https://l.facebook.com') || referrer.startsWith('https://www.linkedin.com') || referrer.startsWith('https://www.instagram.com') || referrer.startsWith('https://www.bing.com') || referrer.startsWith('https://www.google.com') || referrer.startsWith('https://www.pinterest.com')) {
      if (isObjEmpty(params)) {
        return {
          utm_source: domainReferrer,
          utm_medium: referrer.startsWith('https://www.bing.com') || referrer.startsWith('https://www.google.com') ? 'organic' : 'social',
        };
      }
      if (referrer.startsWith('https://l.facebook.com')) {
        return {
          utm_source: domainReferrer,
          utm_medium: 'social',
        };
      }
      if (referrer.startsWith('https://www.google.com') && params && params.gclid) {
        return {
          utm_source: domainReferrer,
          utm_medium: 'cpc',
          utm_content: params.matchtype,
          utm_term: params.keyword,
        }
      }
      return filterParams(params);
    }
    if(referrer && referrer.length > 0 && currentSiteDomain !== domainReferrer) {
      if (params && params.gclid) {
        return {
          utm_source: params.utm_source,
          utm_medium: params.utm_medium,
          utm_campaign: params.utm_campaign,
        };
      }
      return {
        utm_source: domainReferrer,
        utm_medium: 'referral',
      }
    }
    if (isObjEmpty(filterParams(params)))
      return {};
    return filterParams(params);
  };

  const getCookie = name => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

  useEffect(() => {
    const params = getParams(window.location.search);
    
    const cookieUtmParams = JSON.parse(getCookie('utm_params'));

    if(!isObjEmpty(params) && isObjEmpty(cookieUtmParams)) {
      utms = {...params, referrer: document.referrer};
      document.cookie = 'utm_params=' + JSON.stringify({...params, referrer: document.referrer}) + '; ' + document.cookie;
    } else {
      utms = filterParams(cookieUtmParams);
    }
  });


  const handleTrendFormSubmit = event => {
    event.preventDefault();
    let trend_formData = {};
    let isInvalid = false;
    const inputs = Array.prototype.slice.call(document.querySelectorAll(".trend_ccl-input"), 0);
    for (let index = 0; index < inputs.length; index++) {
      const input = inputs[index];
      if (!input.value || input.selectedIndex < 1) {
        isInvalid = true;
        input.classList.add('invalid');
        continue;
      } else {
        input.classList.remove('invalid');
        switch (input.id) {
          case 'trend_contact_first_name':
            trend_formData.contact_first_name = input.value;
            break;
          case 'trend_contact_last_name':
            trend_formData.contact_last_name = input.value;
            break;
          case 'trend_Email':
            trend_formData.Email = input.value;
            break;
          case 'trend_contact_phone':
            trend_formData.contact_phone = input.value;
            break;
          case 'trend_organization':
            trend_formData.organization = input.value;
            break;
          case 'trend_contact_country':
            trend_formData.contact_country = Countries.list[input.selectedIndex - 1].value;
            break;
          case 'trend_ccl_level_in_organization':
            trend_formData.ccl_level_in_organization = userRoles.list[input.selectedIndex - 1].value;
            break;
          default:
            break;
        }
      }
    }

    setTrendInvalidForm(isInvalid);

    if (!isInvalid) {
      trend_formData.campaign_track = 'lns-download';
      trend_formData.interest_code = 'FORMPROMODWNLLNS';

      trend_formData.utm_campaign = utms.utm_campaign;
      trend_formData.utm_medium = utms.utm_medium;
      trend_formData.utm_source = utms.utm_source;
      trend_formData.utm_content = utms.utm_content;
      trend_formData.utm_term = utms.utm_term;
      trend_formData.referrer = utms.referrer;

      trend_formData.formSourceName = 'StandardForm';
      if (getUpdates) {
        trend_formData.consent_date_stamp = timestamp
        trend_formData.leading_effectively = 'Yes'
        trend_formData.global_subscription = 'Yes'
      } else {
        trend_formData.leading_effectively = 'No'
        trend_formData.global_subscription = 'No'
      }
      
      trend_formData.device_type = navigator.userAgent;
      trend_formData.landing_page = 'https://www.leadershipneverstops.org/';
      trend_formData.page_form_completed = window.location.href;
      trend_formData.referrer = document.referrer;

      submitTrendForm(trend_formData);
    }
  };

  const submitTrendForm = trendFormData => {
    axios({
      method: 'POST',
      url: 'https://ccl-form-api.herokuapp.com/trend',
      data: trendFormData
    })
      .then(function (response) {
        //handle success
        setShowThanks(true);
        let thanksPage = document.getElementById('ccl-trends');
        thanksPage.scrollIntoView({ behavior: 'smooth', block: 'start' });
        console.log(response);
        window.dataLayer.push({
          'event': 'formSubmit',
          'gtm.elementId': "Trend Form"
        });
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  return (
    <div className="ccl-trend-form">
      {!showThanks && (
        <div id="trend-form-view">
          <div id="trend-title">
            <h3>Get the report</h3>
            <h4>Talent Reimagined for 2020: The Human Element of Disruption</h4>
            <p className="required-title">All fields are required.</p>
            {trendInvalidForm && (
              <p id="journey-error" className="ccl-form-add-required">
                Please add the required fields
              </p>
            )}
          </div>
          <form id="trendForm">
            <div className="form-item">
              <label htmlFor="contact_first_name">First name:</label>
              <input type="input" name="contact_first_name" className="trend_ccl-input" id="trend_contact_first_name" placeholder="First name" />
            </div>
            <div className="form-item">
              <label htmlFor="contact_last_name">Last name:</label>
              <input type="input" name="contact_last_name" className="trend_ccl-input" id="trend_contact_last_name" placeholder="Last name" />
            </div>
            <div className="form-item">
              <label htmlFor="Email">Email:</label>
              <input type="email" name="Email" className="trend_ccl-input" id="trend_Email" placeholder="Enter your email address" />
            </div>
            <div className="form-item">
              <label htmlFor="contact_phone">Phone:</label>
              <input type="input" name="contact_phone" className="trend_ccl-input" id="trend_contact_phone" placeholder="Phone number" />
            </div>
            <div className="form-item">
              <label htmlFor="contact_country">Country:</label>
              <select as="select" name="contact_country" className="trend_ccl-input" id="trend_contact_country">
                <option className="option-placeholder">Select country</option>
                {Countries.list.map(country => (
                  <option value={country.value.toUpperCase()} key={country.value}>{country.value}</option>
                ))}
              </select>
            </div>
            <div className="form-item">
              <label htmlFor="organization">Company/Organization:</label>
              <input type="input" name="organization" className="trend_ccl-input" id="trend_organization" placeholder="Company/Organization" />
            </div>
            <div className="form-item">
              <label htmlFor="ccl_level_in_organization">Role in Organization:</label>
              <select as="select" name="ccl_level_in_organization" className="trend_ccl-input" id="trend_ccl_level_in_organization">
                <option className="option-placeholder">Select role</option>
                {userRoles.list.map(role => (
                  <option value={role.value} key={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>
            </div>
            <input type="hidden" name="campaign_track" data="lns-download" value="lns-download"></input>
            <input type="hidden" name="interest_code" data="FORMPROMODWNLLNS" value="FORMPROMODWNLLNS"></input>
            <input type="hidden" name="consent_date_stamp" value={timestamp}></input>
            <input type="hidden" name="utm_campaign" value={utms.utm_campaign}></input>
            <input type="hidden" name="utm_medium" value={utms.utm_medium}></input>
            <input type="hidden" name="utm_source" value={utms.utm_source}></input>
            <input type="hidden" name="formSourceName" value="StandardForm"></input>
            <input type="hidden" name="sp_exp" value="yes"></input>

            <div className="form-end">
              <div className="form-updates">
                <input
                  type="checkbox"
                  name="updates"
                  id="ccl-trends-updates"
                  checked={getUpdates}
                  onChange={() => {
                    setGetUpdates(getUpdates ? false : true);
                  }}
                />
                <label htmlFor="ccl-trends-updates" className="ccl-update-label">
                  Yes, I’d like regular updates on thought leadership content, industry insights, and upcoming events from CCL. I understand I can opt out or change my subscription at any time through
                  CCL’s{' '}
                  <a href="https://www.ccl.org/preference-center/" target="_blank">
                    Email Preference Center.
                  </a>
                </label>
              </div>
              <div className="form-submit">
                <p>By submitting this form, you acknowledge that the Center for Creative Leadership (CCL) will use this information to contact you.</p>
                <CCLButton label="Download Report" type="submit" style="primary" id="ccl-journey-form" arrow="true" onClick={e => handleTrendFormSubmit(e)}></CCLButton>
              </div>
            </div>
          </form>
        </div>
      )}
      {showThanks && (
        <div className="form-thanks">
          <h3>You're all set!</h3>
          <p>
            <a href="https://www.ccl.org/wp-content/uploads/2020/01/talent-reimagined-disruption-2020-trends-report-center-for-creative-leadership.pdf" target="_blank">
              Download the Trends Report here.
            </a>
          </p>
          <p>We've also sent a copy to the email address you provided.</p>
          <CCLButton label="GOT IT, THANKS" type="submit" style="outline" onClick={() => props.closeForm()}></CCLButton>
        </div>
      )}
    </div>
  );
};

export default TrendsForm;
